<script setup lang="ts">
useHead({
  bodyAttrs: {
    class: 'dark:bg-gray-950',
  },
})
</script>

<template>
  <div>
    <div class="h-screen flex-col flex items-center -mt-10 justify-center" style="background: url('/assets/images/auth-overlay.svg') no-repeat center center; background-size: contain;">
      <a class="mb-8" href="/"><img src="/assets/images/logo.svg" /></a>
      <div class="relative z-10">
        <slot />
      </div>
    </div>
  </div>
</template>
